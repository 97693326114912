.hall-about-location {
    width: 100%;
    height: auto;
    padding: 3rem 0;
}

.hal-content {
    width: auto;
    height: 100%;
    margin: 0 80px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 80px;
    row-gap: 30px;
}

.hal-column {
    width: 100%;
    height: 480px;
}

.hal-column:nth-child(1) {
    z-index: 1;
}

.hal-column:nth-child(1) iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hal-column:nth-child(2) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.hal-column:nth-child(2) .hal-column-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hal-column:nth-child(2) .hal-column-content h1 {
    font-size: 3rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    color: var(--white);
    margin-bottom: 4rem;
    position: relative;
    font-family: "Brandon", sans-serif;
}

.hal-column:nth-child(2) .hal-column-content h1::after {
    position: absolute;
    content: '';
    bottom: -2rem;
    left: 0;
    width: 100px;
    height: 8px;
    background-color: var(--hall-dark);
    border-radius: 8px;
}

.hal-column:nth-child(2) .hal-column-content p {
    max-width: 400px;
    color: var(--white);
    margin-bottom: 2rem;
    font-family: "Brandon", sans-serif;
    font-size: 1.2rem;
}

.hal-column:nth-child(2) .hal-column-content a {
    display: flex;
    width: 250px;
    height: 45px;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
    background-color: var(--hall-dark);
    position: relative;
}

.hal-column:nth-child(2) .hal-column-content a h3 {
    text-transform: uppercase;
    z-index: 1;
    color: var(--white);
    transition: .4s ease;
    font-weight: 100;
    font-family: "Brandon", sans-serif;
}

.hal-column:nth-child(2) .hal-column-content a::after {
    position: absolute;
    content: '';
    width: 0%;
    height: 100%;
    background-color: var(--white);
    color: var(--hall-dark);
    transition: width .4s ease;
}

.hal-column:nth-child(2) .hal-column-content a:hover::after {
    width: 100%;
}

.hal-column:nth-child(2) .hal-column-content a:hover h3 {
    color: var(--hall-dark);
}

.hc-animation {
    position: relative;
}

.hc-animation::after {
    position: absolute;
    z-index: -2;
    background-color: var(--hall-dark);
    opacity: .6;
    height: 100%;
    width: 85%;
    border-radius: 50%;
    content: '';
}

.hc-animation::before {
    position: absolute;
    z-index: -2;
    background-color: var(--restaurant-background);
    height: 100%;
    width: 90%;
    border-radius: 50%;
    content: '';
    z-index: -1;
    animation: showHide 10s ease infinite;
}

@media only screen and (max-width: 1440px) {
    .hal-content {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 1024px) {
    .hal-content {
        margin: 0 25px;
    }

    .hc-animation::after {
        height: 80%;
    }

    .hc-animation::before {
        height: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .hal-content {
        margin: 0 15px;
        grid-template-columns: repeat(1,1fr);
    }

    .hal-column:nth-child(2) {
        height: auto;
    }

    .hal-column:nth-child(2) p {
        max-width: 100%;
    }

    .hc-animation::after {
        height: 80%;
        width: 60%;
    }

    .hc-animation::before {
        height: 80%;
        width: 65%;
    }
}

@media only screen and (max-width: 480px) {
    .hal-content {
        margin: 0 10px;
    }

    .hc-animation::after {
        display: none;
    }

    .hc-animation::before {
        display: none;
    }
}

@media only screen and (max-width: 376px) {
    .hal-content {
        margin: 0 5px;
    }
}