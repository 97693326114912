.restaurant-menu {
    width: 100%;
    height: auto;
    position: relative;
    padding: 3rem 0;
}

.rm-leaves-img {
    position: absolute;
    top: 50%;
    left: calc(50% - 45%);
    transform: translate(-50%, -50%);
    height: 800px;
    z-index: 100;
}

.rm-tree-right {
    position: absolute;
    left: calc(50% + 38%);
    top: calc(50% + 30%);
    transform: translate(-50%, -50%);
    z-index: 100;
    height: 680px;
}

.rm-content {
    width: auto;
    height: 100%;
    margin: 0 80px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    row-gap: 30px;
    column-gap: 30px;
}

.rmc-column {
    width: 100%;
    height: 380px !important;
}

.rmc-column:nth-child(1) {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: hidden;
}

.rmc-column:nth-child(2) {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 100;
    background-color: rgba(154, 180, 152, 0.6);
    backdrop-filter: blur(2px);
}

.rmc-column:nth-child(2) h1 {
    font-size: 2.4rem;
    color: var(--dark-color);
    font-family: var(--restaurant-font);
    user-select: none;
    padding: .4rem 1rem;
}

.rmc-column:nth-child(2) .rmc-h3 {
    color: var(--restaurant-text);
    font-family: var(--restaurant-font);
    user-select: none;
    padding: 0 1rem;
    font-size: 1.3rem;
}

.rmc-column:nth-child(2) a {
    width: 200px;
    height: 45px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    position: relative;
    margin: .4rem 1rem;
}

.rmc-column:nth-child(2) a::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--dark-color);
    width: 0%;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    transition: all .3s ease;
}


.rmc-column:nth-child(2) a:hover::after {
    width: 100%;
}

.rmc-column:nth-child(2) a h3 {
    color: var(--dark-color);
    font-family: var(--restaurant-font);
    z-index: 1;
    transition: color .3s ease;
}

.rmc-column:nth-child(2) a:hover h3 {
    color: var(--white);
}

@media only screen and (max-width: 1440px) {
    .rm-content {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 1024px) {
    .rm-content {
        margin: 0 25px;
    }
}

@media only screen and (max-width: 768px) {
    .rm-content {
        margin: 0 15px;
        grid-template-columns: repeat(1, 1fr);
    }

    .rm-branch-left {
        position: absolute;
        top: calc(50% - 1%);
        left: calc(50% - 30%);
        transform: translate(-50%, -50%);
        z-index: 50;
        width: 500px;
    }

    .rm-decoration1 {
        position: absolute;
        top: calc(50% - 50%);
        left: calc(50% + 45%);
        transform: translate(-50%, -50%);
        height: 180px;
        z-index: 100;
    }

    .rmc-column:nth-child(2) h1 {
        color: var(--white);
    }
}

@media only screen and (max-width: 480px) {
    .rm-content {
        margin: 0 10px;
    }

    .rmc-column:nth-child(2) .rmc-h3 {
        font-size: 1rem;
    }

    .rmc-column:nth-child(1) .rmc-tree1 {
        left: calc(50% - 50%);
    }

    .rmc-column:nth-child(1) .rmc-tree2 {
        left: calc(50% + 50%);
    }

    .rmc-column:nth-child(1) .rmc-meal {
        width: 120%;
    }

    .rm-decoration1 {
        position: absolute;
        top: calc(50% - 50%);
        left: calc(50% + 45%);
        transform: translate(-50%, -50%);
        height: 150px;
        z-index: 100;
    }

    .rm-leaves-img {
        position: absolute;
        left: -150px;
        height: 800px;
        z-index: 100;
        top: -150px;
        transform: scaleY(-1);
    }

    .rmc-column:nth-child(2) h1 {
        padding: .4rem .6rem;
    }
    
    .rmc-column:nth-child(2) .rmc-h3 {
        padding: 0 .6rem;
    }
    
    .rmc-column:nth-child(2) a {
        margin: .4rem .6rem;
    }
}

@media only screen and (max-width: 376px) {
    .rm-content {
        margin: 0 5px;
    }

    .rm-decoration2 {
        position: absolute;
        top: calc(50% + 50%);
        left: calc(50% - 48%);
        transform: translate(-50%, -50%);
        height: 200px;
        z-index: 100;
    }

    .rmc-column:nth-child(2) .rmc-h3 {
        font-size: .9rem;
    }
}