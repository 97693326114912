.gameroom-component {
    width: 100%;
    height: auto;
    position: relative;
    padding: 3rem 0;
    overflow: hidden;
    z-index: 2;
}

.grc-content {
    width: auto;
    height: 100%;
    margin: 0 80px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
    column-gap: 30px;
}

.grc-column {
    width: 100%;
    height: 380px;
}

.grc-column:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.grc-column:nth-child(1) h3 {
    color: var(--restaurant-text);
    font-family: var(--restaurant-font);
    user-select: none;
}

.grc-column:nth-child(2) img {
    width: 100%;
    height: 100%;
    object-fit: cover;   
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    user-select: none;
    pointer-events: none;
}

@media only screen and (max-width: 1440px) {
    .grc-content {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 1024px) {
    .grc-content {
        margin: 0 25px;
    }
}

@media only screen and (max-width: 768px) {
    .grc-content {
        margin: 0 15px;
        grid-template-columns: repeat(1, 1fr);
    }

    .grc-column:nth-child(1) {
        height: auto;
    }
}

@media only screen and (max-width: 480px) {
    .grc-content {
        margin: 0 10px;
    }

    .grc-column:nth-child(1) h3 {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 376px) {
    .grc-content {
        margin: 0 5px;
    }

    .grc-column:nth-child(1) h3 {
        font-size: .9rem;
    }
}