:root {
    --slideWidth: 280px;
    --slideCount: 4;
}

.rm-slider-wrap-carousel {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
}

.rm-slider-inner-carousel {
    height: 380px;
    position: relative;
    width: calc(var(--slideWidth) * 3);
}

.rm-slider-container-carousel {
    height: 380px;
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rm-carousel-btn {
    position: absolute;
    z-index: 20;
    border: none;
    outline: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-color);
    width: 38px;
    height: 38px;
    opacity: .5;
    transition: opacity .6s ease;
    cursor: pointer;
}

.rm-carousel-btn:hover {
    opacity: 1;
}

.rm-carousel-btn svg {
    color: var(--white);
}

.rm-carousel-prev {
    left: 0;
}

.rm-carousel-next {
    right: 0;
}

.rm-slider-carousel-list {
    height: 100%;
    left: 50%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    transform: translateX(-50%);
    width: calc((var(--slideCount) + 0.5) * (var(--slideWidth) * 2));
}

.rm-item {
    display: inline-block;
    height: 380px;
    margin: 0;
    position: absolute;
    transition: all 0.3s;
    width: var(--slideWidth);
    display: flex;
    align-items: center;
    justify-content: center;
}

.rm-item-inner {
    width: 95%;
    height: 95%;
    border-radius: 8px;
    overflow: hidden;
}

.rm-item-inner .rm-item-top {
    height: 65%;
    width: 100%;
    overflow: hidden;
}

.rm-item-inner .rm-item-bottom {
    height: 35%;
    background-color: var(--dark-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.rm-item-inner .rm-item-top img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.rm-item-inner .rm-item-bottom .rm-itemb-text {
    width: 90%;
    height: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.rm-item-inner .rm-item-bottom .rm-itemb-text h1 {
    width: 100%;
    font-family: var(--restaurant-font);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--white);
    font-weight: 100;
    font-size: 1.2rem;
}

.rm-item-inner .rm-item-bottom .rm-itemb-text h1 svg {
    font-size: 2rem;
}

.rm-item-inner .rm-item-bottom .rm-itemb-text p {
    text-align: left;
    color: var(--white);
    font-family: var(--restaurant-font);
    font-size: .8rem;
}