.hall-cards {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.hall-card {
    width: 100%;
    height: 140px;
    position: relative;
    transition: height 1s ease;
}

.hct-main {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 80px;
}

.hct-main h1 {
    color: var(--white);
    font-size: 3rem;
    z-index: 1;
    transition: all .4s ease;
    text-transform: uppercase;
    font-family: "Brandon", sans-serif;
}

.hall-card:nth-child(2) {
    margin-top: 5px;
}

.hall-card:nth-child(3) {
    margin-top: 5px;
}

.hall-card::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(58,77,57, 0.7);
}

.hall-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hall-card-text {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100% !important;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin: 0 80px;
    z-index: 2;
}

.hall-card-text p {
    height: 0;
    font-family: "Brandon", sans-serif;
}

.hall-card-text h1 {
    color: var(--white);
    text-transform: uppercase;
    font-size: 3rem;
    transform: translateX(-100%);
    opacity: 0;
    height: 0;
    transition: opacity .4s ease, height .4s ease, transform .4s ease;
    font-family: "Brandon", sans-serif;
}

.hall-card.active {
    height: 400px;
    overflow: hidden;
}

.hall-card.active .hct-main h1 {
    opacity: 0;
    transform: translateX(-100%);
    height: 0;
    font-family: "Brandon", sans-serif;
}

.hall-card.active .hall-card-text {
    opacity: 1;
}

.hall-card.active::after {
    background-color: rgba(250, 250, 250, 0.3);
    transform: rotate(25deg);
    width: 60%;
    height: 200%;
    transform-origin: top right;
}

.hall-card.active::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 55%;
    height: 200%;
    transform: rotate(25deg);
    transform-origin: top right;
    background-color: rgba(250, 250, 250, 0.5);
}

.hall-card.active .hall-card-text h1 {
    display: flex;
    flex-direction: column;
    color: var(--hall-dark);
    margin-bottom: 4rem;
    opacity: 1;
    transform: translateX(0);
    height: auto;
    font-family: "Brandon", sans-serif;
}

.hall-card.active .hct-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 140%;
    transform: rotate(25deg);
    background-color: rgba(250,250,250,0.7);
    transform-origin: top right;
}

.hct-may {
    transform: translateX(-100%);
    opacity: 0;
    height: 0;
    transition: opacity .4s ease, height .4s ease, transform .4s ease;
    transition-delay: .2s;
}

.hct-cap {
    transform: translateX(-100%);
    opacity: 0;
    height: 0;
    transition: opacity .4s ease, height .4s ease, transform .4s ease;
    transition-delay: .6s;
}

.hall-card.active .hall-card-text .hct-cap {
    font-weight: bold;
    margin-top: 1rem;
    height: auto;
    opacity: 1;
    transform: translateX(0);
    font-family: "Brandon", sans-serif;
}

.hall-card.active .hall-card-text .hct-may {
    font-weight: bold;
    height: auto;
    opacity: 1;
    transform: translateX(0);
    font-family: "Brandon", sans-serif;
}

@media only screen and (max-width: 1440px) {
    .hall-card-text {
        margin: 0 50px;
    }

    .hct-main {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 1024px) {
    .hall-card-text {
        margin: 0 25px;
    }
    .hct-main {
        margin: 0 25px;
    }
}

@media only screen and (max-width: 768px) {
    .hall-card-text {
        margin: 0 15px;
    }

    .hct-main {
        margin: 0 15px;
    }
}

@media only screen and (max-width: 480px) {
    .hall-card-text {
        margin: 0 10px;
    }

    .hct-main {
        margin: 0 10px;
    }

    .hall-card.active::after {
        width: 120%;
    }
    
    .hall-card.active::before {
        width: 100%;
    }

    .hall-card.active .hct-overlay {
        width: 80%;
    }
}

@media only screen and (max-width: 376px) {
    .hall-card-text {
        margin: 0 5px;
    }

    .hct-main {
        margin: 0 5px;
    }
}