.gameroom-hero {
    height: calc(100vh - 100px);
    width: 100%;
    position: relative;
    z-index: 2;
}

.gh-img {
    position: absolute;
    top: calc(50% + 35%);
    left: calc(50% - 38%);
    transform: translate(-50%, -50%);
    z-index: 2;
    height: 500px;
}

.gh-main-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
}

.gameroom-hero .gh-main-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gh-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    z-index: 10;
}

.ghc-text {
    width: auto;
    height: 100%;
    margin: 0 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ghc-text h1 {
    font-family: var(--restaurant-font);
    color: var(--white);
    margin-bottom: 3rem;
    text-align: center;
    user-select: none;
    animation: 1s ease fromTop forwards;
    font-size: 2.4rem;
    text-transform: uppercase;
    font-weight: 100;
}

@keyframes fromTop {
    0%{
        transform: translateY(-100px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

.ghc-text h3 {
    font-family: var(--restaurant-font);
    color: var(--white);
    text-align: center;
    user-select: none;
    animation: 1s ease fromBottom forwards;
    font-size: 1.3rem;
}

@keyframes fromBottom {
    0%{
        transform: translateY(100px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

@media only screen and (max-width: 1440px) {
    .ghc-text {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 1024px) {
    .ghc-text {
        margin: 0 25px;
    }
}

@media only screen and (max-width: 768px) {
    .ghc-text {
        margin: 0 15px;
    }

    .gh-img {
        position: absolute;
        top: calc(50% + 33%);
        left: calc(50% - 38%);
        transform: translate(-50%, -50%);
        z-index: 2;
        height: 400px;
    }
}

@media only screen and (max-width: 480px) {
    .ghc-text {
        margin: 0 10px;
    }

    .ghc-text h3 {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 376px) {
    .ghc-text {
        margin: 0 5px;
    }

    .ghc-text h3 {
        font-size: .9rem;
    }
}