.loader-section {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.loader-section .ls-leaves {
    width: auto;
    height: 90%;
    animation: rotateAndDisappear 3s ease forwards;
    pointer-events: none;
    z-index: 1;
    position: absolute;
}

@keyframes rotateAndDisappear {
    0% {
        transform: scale(0.9);
        opacity: .6;
    }
    25% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(0.9);
        opacity: .6;
    }
    75% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.1);
        opacity: 0;
    }
}

.loader-section .ls-logo {
    position: absolute;
    width: auto;
    height: auto;
    z-index: 5;
    pointer-events: none;
}

@media only screen and (max-width: 480px) {
    .loader-section .ls-leaves {
        width: 100%;
        height: auto;
    }

    .loader-section .ls-logo {
        width: 90%;
        z-index: 1;
    }
}