.hall-text {
    width: 100%;
    height: auto;
    padding: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hall-text p {
    text-align: center;
    color: var(--white);
    font-family: "Brandon", sans-serif;
    font-size: 1.2rem;
}