.hall-hero {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.hh-image-slider {
    width: 100%;
    height: 100%;
}

.hh-image-slider .hh-slider {
    width: 100%;
    height: 100%;
    position: absolute;
}

.hh-image-slider .hh-slider .hh-slider-card {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: imageSlider 1s ease forwards;
}

@keyframes imageSlider {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.hh-image-slider .hh-slider .hh-slider-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hh-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}

.hh-content {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.hh-content-text {
    width: auto;
    height: 100%;
    margin: 0 80px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    z-index: 3;
}

.hh-content-text .hh-title {
    display: flex;
    flex-direction: column;
    font-size: 3.4rem;
    color: var(--white);
    position: relative;
    margin-bottom: 4rem;
    user-select: none;
    text-transform: uppercase;
    animation: appearTextLeft .4s ease forwards;
    font-family: "Brandon", sans-serif;
}

.hh-content-text .hh-title::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 8px;
    background-color: var(--restaurant-background);
    bottom: -2rem;
    left: 0;
    border-radius: 2px;
}

.hh-content-text .hh-desc {
    max-width: 400px;
    color: var(--white);
    user-select: none;
    margin-bottom: 2rem;
    animation: appearTextLeft .4s ease forwards;
    animation-delay: .2s;
    opacity: 0;
    font-family: "Brandon", sans-serif;
}

.hh-content-text a {
    width: 250px;
    height: 40px;
    border: 4px solid var(--restaurant-background);
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
    position: relative;
    animation: appearTextLeft .4s ease forwards;
    animation-delay: .4s;
    opacity: 0;
}

.hh-content-text a::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: var(--restaurant-background);
    z-index: 1;
    transition: width .4s ease;
}

.hh-content-text a:hover::after {
    width: 100%;
}

.hh-content-text a h3 {
    text-transform: uppercase;
    color: var(--restaurant-background);
    z-index: 2;
    transition: color .4s ease;
    font-family: "Brandon", sans-serif;
}

.hh-content-text a:hover h3 {
    color: var(--white);
}

.hh-content-text a svg {
    color: var(--restaurant-background);
    transition: color .4s ease;
    z-index: 2;
}

.hh-content-text a:hover svg {
    color: var(--white);
}

@media only screen and (max-width: 1440px) {
    .hh-content-text {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 1024px) {
    .hh-content-text {
        margin: 0 25px;
    }
}

@media only screen and (max-width: 768px) {
    .hh-content-text {
        margin: 0 15px;
    }

    .hh-content-text .hh-title {
        font-size: 3rem;
    }

    .hh-content-text .hh-desc {
        font-size: 1rem;
        max-width: 55%;
    }
}

@media only screen and (max-width: 480px) {
    .hh-content-text {
        margin: 0 10px;
    }

    .hh-content-text .hh-title {
        font-size: 2.6rem;
    }

    .hh-content-text .hh-desc {
        font-size: 1rem;
        max-width: 80%;
    }
}

@media only screen and (max-width: 376px) {
    .hh-content-text {
        margin: 0 5px;
    }

    .hh-content-text .hh-title {
        font-size: 2.4rem;
    }

    .hh-content-text .hh-desc {
        font-size: 1rem;
        max-width: 100%;
    }
}
