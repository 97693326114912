.all-halls-tabs {
    width: 100%;
    height: auto;
}

.aht-tabs {
    width: 100%;
    height: 100%;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background-color: var(--hall-dark);
}

.aht-tabs .tab-button {
    width: 100%;
    height: 60px !important;
    border: none;
    outline: none;
    background-color: var(--hall-dark);
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: var(--white);
}

.aht-tabs .tab-button.active {
    background-color: var(--restaurant-background);
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.aht-tabs .tab-button.active::after {
    position: absolute;
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    background-image: radial-gradient(circle at 100% 100%, transparent 1.5rem, var(--restaurant-background) 1.5rem);
    transform: rotate(-90deg);
    z-index: 3;
    bottom: 0;
    right: -1.5rem;
}

.aht-tabs .tab-button.active::before {
    position: absolute;
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    background-image: radial-gradient(circle at 100% 100%, transparent 1.5rem, var(--restaurant-background) 1.5rem);
    transform: rotate(-180deg);
    z-index: 3;
    bottom: 0;
    left: -1.5rem;
}

.aht-wrap {
    width: 100%;
    height: auto;
    padding: 3rem 0;
    background-color: var(--restaurant-background);
}

.aht-content {
    width: auto;
    height: 100%;
    margin: 0 80px;
}

.aht-text {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.aht-text h1 {
    font-size: 3rem;
    color: var(--white);
    text-transform: uppercase;
    position: relative;
    margin-bottom: 4rem;
    font-family: "Brandon", sans-serif;
}

.aht-text h1::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 8px;
    border-radius: 8px;
    background-color: var(--hall-dark);
    left: 50%;
    transform: translate(-50%);
    bottom: -2rem;
}

.aht-text p {
    color: var(--white);
    max-width: 800px;
    text-align: center;
    margin-bottom: 2rem;
    font-family: "Brandon", sans-serif;
    font-size: 1.2rem;
}

.aht-button-wrap {
    width: 100%;
    height: auto;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

.aht-button {
    display: flex;
    width: 200px;
    height: 45px;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
    position: relative;
    background-color: var(--hall-dark);
}

.aht-button h3 {
    text-transform: uppercase;
    z-index: 1;
    color: var(--white);
    transition: .4s ease;
    font-family: "Brandon", sans-serif;
}

.aht-button svg {
    text-transform: uppercase;
    z-index: 2;
    color: var(--white);
    transition: .4s ease;
}

.aht-button::after {
    position: absolute;
    content: '';
    width: 0%;
    height: 100%;
    background-color: var(--white);
    color: var(--white);
    transition: width .4s ease;
}

.aht-button:hover::after {
    width: 100%;
}

.aht-button:hover h3 {
    color: var(--hall-dark);
}

.aht-button:hover svg {
    color: var(--hall-dark);
}

@media only screen and (max-width: 1440px) {
    .aht-content {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 1024px) {
    .aht-content {
        margin: 0 25px;
    }

    .aht-tabs .tab-button {
        font-size: 1rem !important;
    }
}


@media only screen and (max-width: 768px) {
    .aht-content {
        margin: 0 15px;
    }

    .aht-tabs .tab-button {
        font-size: .9rem !important;
    }
}

@media only screen and (max-width: 480px) {
    .aht-content {
        margin: 0 10px;
    }

    .aht-tabs {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        background-color: var(--restaurant-background);
        margin-top: 10px;
    }

    .aht-tabs .tab-button.active {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .aht-tabs .tab-button.active::after {
        display: none !important;
    }

    .aht-tabs .tab-button.active::before {
        display: none !important;
    }

    .aht-text h1 {
        font-size: 2.6rem;
    }

    .aht-text p {
        max-width: 100%;
    }
}

@media only screen and (max-width: 376px) {
    .aht-content {
        margin: 0 5px;
    }

    .aht-tabs .tab-button {
        font-size: .8rem !important;
    }
}