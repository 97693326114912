.all-halls-hero {
    width: 100%;
    height: 100vh;
    max-height: 1000px;
}

.all-halls-hero img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ahh-wrap {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.ahh-content {
    width: auto;
    height: 100%;
    margin: 0 80px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.ahh-title {
    display: flex;
    flex-direction: column;
    font-size: 3rem;
    color: var(--white);
    text-transform: uppercase;
    position: relative;
    margin-bottom: 4rem;
    animation: appearTextLeft .4s ease forwards;
    font-family: "Brandon", sans-serif;
}

.ahh-title::after {
    position: absolute;
    content: '';
    bottom: -2rem;
    left: 0;
    width: 100px;
    height: 8px;
    background-color: var(--restaurant-background);
    border-radius: 8px;
}

.ahh-desc {
    max-width: 400px;
    color: var(--white);
    animation: appearTextLeft .4s ease forwards;
    opacity: 0;
    animation-delay: .2s;
    font-family: "Brandon", sans-serif;
    font-size: 1.2rem;
}

.ahh-btn {
    display: flex;
    width: 200px;
    height: 45px;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
    border: 3px solid var(--restaurant-background);
    position: relative;
    margin-top: 2rem;
    animation: appearTextLeft .4s ease forwards;
    opacity: 0;
    animation-delay: .4s;
}

.ahh-btn h3 {
    text-transform: uppercase;
    z-index: 1;
    color: var(--restaurant-background);
    transition: .4s ease;
    font-family: "Brandon", sans-serif;
}

.ahh-btn svg {
    text-transform: uppercase;
    z-index: 1;
    color: var(--restaurant-background);
    transition: .4s ease;
}

.ahh-btn::after {
    position: absolute;
    content: '';
    width: 0%;
    height: 100%;
    background-color: var(--restaurant-background);
    color: var(--white);
    transition: width .4s ease;
}

.ahh-btn:hover::after {
    width: 100%;
}

.ahh-btn:hover h3 {
    color: var(--white);
}

.ahh-btn:hover svg {
    color: var(--white);
}

@media only screen and (max-width: 1440px) {
    .ahh-content {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 1024px) {
    .ahh-content {
        margin: 0 25px;
    }
}

@media only screen and (max-width: 768px) {
    .ahh-content {
        margin: 0 15px;
    }
}

@media only screen and (max-width: 480px) {
    .ahh-content {
        margin: 0 10px;
    }

    .ahh-desc {
        max-width: 100%;
    }
}

@media only screen and (max-width: 376px) {
    .ahh-content {
        margin: 0 5px;
    }

    .ahh-title {
        font-size: 2.6rem;
    }
}