.hst-slider-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
}

.hst-slider {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.hst-btn {
    position: absolute;
    z-index: 3;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    outline: none;
}

.hst-prev {
    left: 40px;
}

.hst-next {
    right: 40px;
}

.hst-slider-content {
    width: 100%;
    height: 100%;
    position: absolute;
}

.hst-slide-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: hallOpacity 1s ease forwards;
}

@keyframes hallOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.hst-slide-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hst-indicators {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin-top: 10px;
    column-gap: 5px;
}

.hst-indicators .hst-indicator {
    width: 100%;
    height: 8px;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
}

.hst-indicators .hst-indicator.active {
    background-color: var(--hall-dark);
}