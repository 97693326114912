.hall-layout-left {
    width: 100%;
    height: auto;
    padding: 3rem 0;
}

.hall-layout-left-content {
    margin: 0 80px;
    width: auto;
    height: 100%;
    display: grid;
    column-gap: 80px;
    row-gap: 30px;
    grid-template-columns: repeat(2,1fr);
}

.hall-layout-left-column {
    width: 100%;
    height: 480px;
}

.hall-layout-left-column:nth-child(1) {
    z-index: 1;
}

.hall-layout-left-column:nth-child(1) img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.hall-layout-left-column:nth-child(2) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.hall-layout-left-column:nth-child(2) .hllc-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hall-layout-left-column:nth-child(2) .hllc-content h1 {
    font-size: 3rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 4rem;
    color: var(--white);
    font-family: "Brandon", sans-serif;
}

.hall-layout-left-column:nth-child(2) .hllc-content h1::after {
    position: absolute;
    content: '';
    bottom: -2rem;
    left: 0;
    width: 100px;
    height: 8px;
    background-color: var(--hall-dark);
    border-radius: 8px;
}

.hall-layout-left-column:nth-child(2) .hllc-content p {
    color: var(--white);
    max-width: 400px;
    font-family: "Brandon", sans-serif;
    font-size: 1.2rem;
}

@media only screen and (max-width: 1440px) {
    .hall-layout-left-content {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 1024px) {
    .hall-layout-left-content {
        margin: 0 25px;
    }
}

@media only screen and (max-width: 768px) {
    .hall-layout-left-content {
        margin: 0 15px;
        grid-template-columns: repeat(1,1fr);
    }

    .hall-layout-left-column:nth-child(2) {
        height: auto;
    }
}

@media only screen and (max-width: 480px) {
    .hall-layout-left-content {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 376px) {
    .hall-layout-left-content {
        margin: 0 5px;
    }
}

