.hall-menu-component {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
}

.hall-menu-header {
    width: 100%;
    height: 100%;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: var(--hall-dark);
}

.hall-menu-header .hall-menu-tab {
    width: 100%;
    height: 60px !important;
    border: none;
    outline: none;
    background-color: var(--hall-dark);
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: var(--white);
    font-family: "Brandon", sans-serif;
}

.hall-menu-header .hall-menu-tab.active {
    background-color: var(--restaurant-background);
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.hall-menu-header .hall-menu-tab.active::after {
    position: absolute;
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    background-image: radial-gradient(circle at 100% 100%, transparent 1.5rem, var(--restaurant-background) 1.5rem);
    transform: rotate(-90deg);
    z-index: 3;
    bottom: 0;
    right: -1.5rem;
}

.hall-menu-header .hall-menu-tab.active::before {
    position: absolute;
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    background-image: radial-gradient(circle at 100% 100%, transparent 1.5rem, var(--restaurant-background) 1.5rem);
    transform: rotate(-180deg);
    z-index: 3;
    bottom: 0;
    left: -1.5rem;
}

.hmc-content {
    width: auto;
    height: auto;
    margin: 0 80px;
}

.hmc-container {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 3rem 0;
    column-gap: 30px;
}

.hmc-wrap {
    height: auto;
    border-radius: 400px;
    overflow: hidden;
    position: relative;
}

.hmc-wrap-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 400px;
    background: radial-gradient(ellipse at center, rgba(0,0,0,0) 40%, rgba(0,0,0,1) );
}

.hmc-wrap .hmc-img {
    width: 100%;
    height: 100%;
    border-radius: 400px;
}

.hmc-wrap .hmc-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 400px;
}

.hmc-scroll {
    position: absolute;
    z-index: 2;
    width: 300px;
    height: 300px;
    background-color: var(--hall-dark);
    top: calc(50% - 35%);
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-shadow: 0 0 20px 15px rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.hmc-scroll img {
    width: auto;
    height: 50px;
}

.hmc-menu-title h1 {
    font-size: 3rem;
    color: var(--white);
    text-transform: uppercase;
    position: relative;
    padding-bottom: 4rem;
    font-family: "Brandon", sans-serif;
}

.hmc-menu-title h1::after {
    position: absolute;
    width: 100px;
    height: 8px;
    border-radius: 8px;
    content: '';
    background-color: var(--hall-dark);
    left: 0;
    bottom: 2rem;
}

.hmc-menu2 {
    margin-bottom: 2rem;
    width: auto;
    height: auto;
}

.hmc-menu2:last-child {
    margin-bottom: 0;
}

.hmc-menu-sub {
    padding: 1rem 0;
}

.hmc-menu-sub h3 {
    text-transform: uppercase;
    color: var(--hall-dark);
    font-family: "Brandon", sans-serif;
}

.hmc-menu3 {
    width: auto;
    height: auto;
    padding-top: .5rem;
}

.hmc-menu3 p {
    color: var(--white);
    font-family: "Brandon", sans-serif;
    font-size: 1.2rem;
}

@media only screen and (max-width: 1440px) {
    .hmc-content {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 1024px) {
    .hmc-content {
        margin: 0 25px;
    }

    .hall-menu-header .hall-menu-tab {
        font-size: 1rem !important;
    }
}

@media only screen and (max-width: 768px) {
    .hall-menu-header .hall-menu-tab {
        font-size: .9rem !important;
    }

    .hmc-content {
        margin: 0 15px;
    }
}

@media only screen and (max-width: 480px) {
    .hmc-title h1 {
        font-size: 2.6rem !important;
    }

    .hmc-content {
        margin: 0 10px;
    }

    .hall-menu-header {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        background-color: var(--restaurant-background);
        margin-top: 10px;
    }

    .hall-menu-header  .hall-menu-tab.active {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .hall-menu-header  .hall-menu-tab.active::after {
        display: none !important;
    }

    .hall-menu-header  .hall-menu-tab.active::before {
        display: none !important;
    }

    .hmc-container {
        grid-template-columns: repeat(1,1fr);
    }

    .hmc-wrap {
        display: none;
    }

    .hmc-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .hmc-menu-title {
        text-align: center;
    }

    .hmc-menu-title h1::after {
        left: 50%;
        transform: translate(-50%);
    }
}

@media only screen and (max-width: 376px) {
    .hmc-content {
        margin: 0 5px;
    }
}

