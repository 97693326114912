.all-halls-slider {
    width: 100%;
    height: 540px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.all-halls-slider-wrap {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 20px 20px rgba(0,0,0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.all-halls-slider-wrap:hover .ahsw-btn {
    opacity: .7;
}

.ahsw-btn {
    border: none;
    outline: none;
    width: 60px;
    height: 60px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    cursor: pointer;
    background-color: var(--hall-dark);
    opacity: 0;
    transition: opacity .4s ease;
}

.ahsw-btn:hover {
    opacity: 1 !important
}

.ahsw-btn:hover svg {
    color: var(--white);
}

.ahsw-btn svg {
    color: var(--white);
    font-size: 1.2rem;
}

.ahsw-prev {
    left: 0;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.ahsw-next {
    right: 0;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.all-halls-slider-inner {
    white-space: nowrap;
    height: 100%;
    border-radius: 20px;
    transition: all 1s ease-in-out;
}

.all-halls-slider-item {
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.all-halls-slider-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.all-halls-indicators {
    width: 100%;
    height: 8px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 10px;
}

.all-halls-indicators .ahi-item {
    border-radius: 8px;
    border: none;
    outline: none;
    background-color: var(--white);
    cursor: pointer;
}

.all-halls-indicators .ahi-item.active {
    background-color: var(--hall-dark);
}

@media only screen and (max-width: 480px) {
    .all-halls-slider {
        height: 480px;
    }
}