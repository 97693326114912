.hall-layout-right {
    width: 100%;
    height: auto;
    padding: 3rem 0;
}

.hlr-content {
    margin: 0 80px;
    width: auto;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 80px;
    row-gap: 30px;
}

.hlr-column {
    width: 100%;
    height: 480px;
}

.hlr-column:nth-child(1) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.hlr-column:nth-child(1) .hlr-column-content {
    width: 100%;
}

.hlr-column:nth-child(1) .hlr-column-content h1 {
    color: var(--white);
    font-size: 3rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    position: relative;
    font-family: "Brandon", sans-serif;
}

.hlr-column:nth-child(1) .hlr-column-content h1::after {
    position: absolute;
    content: '';
    bottom: -2rem;
    left: 0;
    width: 100px;
    height: 8px;
    background-color: var(--hall-dark);
    border-radius: 8px;
}

.hlr-column:nth-child(1) .hlr-column-content p {
    max-width: 400px;
    color: var(--white);
    font-family: "Brandon", sans-serif;
    font-size: 1.2rem;
}

.hlr-column:nth-child(2) img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.hlr-animation {
    position: relative;
}

.hlr-animation::after {
    position: absolute;
    z-index: -2;
    background-color: var(--hall-dark);
    opacity: .6;
    height: 100%;
    width: 85%;
    border-radius: 50%;
    content: '';
}

.hlr-animation::before {
    position: absolute;
    z-index: -2;
    background-color: var(--restaurant-background);
    height: 100%;
    width: 90%;
    border-radius: 50%;
    content: '';
    z-index: -1;
    animation: showHideReverse 10s ease infinite;
}

@media only screen and (max-width: 1440px) {
    .hlr-content {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 1024px) {
    .hlr-content {
        margin: 0 25px;
    }

    .hlr-animation::after {
        height: 80%;
    }

    .hlr-animation::before {
        height: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .hall-layout-right {
        padding: 0 !important;
    }

    .hlr-content {
        margin: 0 15px;
        grid-template-columns: repeat(1,1fr);
    }

    .hlr-column:nth-child(1) {
        height: auto;
    }

    .hlr-animation::after {
        height: 80%;
        width: 60%;
    }

    .hlr-animation::before {
        height: 80%;
        width: 65%;
    }
}

@media only screen and (max-width: 480px) {
    .hlr-content {
        margin: 0 10px;
    }

    .hlr-column:nth-child(1) p {
        max-width: 100% !important;
    }

    .hlr-animation::after {
        display: none;
    }

    .hlr-animation::before {
        display: none;
    }
}

@media only screen and (max-width: 376px) {
    .hlr-content {
        margin: 0 5px;
    }
}