.rl-celebrations {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: var(--dark-color);
    z-index: 9999;
    border-radius: 50%;
    border: 2px solid var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
}

.rl-celebrations svg {
    color: var(--white);
    margin-bottom: 2px;
}

.rl-celebrations p {
    color: var(--white);
    font-size: .6rem;
    font-family: var(--restaurant-font);
}

.rl-celebrations::after {
    position: absolute;
    content: 'Posetite';
    font-size: .8rem;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--white);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--dark-color);
    font-weight: bold;
    font-family: var(--restaurant-font);
    animation: showElement 5s ease infinite;
}

@keyframes showElement {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media only screen and (max-width: 768px) {
    .rl-celebrations {
        bottom: 15px;
        right: 15px;
    }
}

@media only screen and (max-width: 480px) {
    .rl-celebrations {
        bottom: 10px;
        right: 10px;
    }
}

@media only screen and (max-width: 376px) {
    .rl-celebrations {
        bottom: 5px;
        right: 5px;
    }
}