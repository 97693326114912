.home-texture {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--restaurant-background);
}

.ht-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: .5;
    object-fit: cover;
}

.ht-crack {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: auto;
    height: 100%;
    opacity: 0;
    transition: opacity 10s ease;
}

.ht-crack.visible {
    opacity: 1;
}