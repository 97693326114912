.gr-texture {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--restaurant-background);
}

.gr-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    opacity: .5;
}